import axios from 'axios';

const token = localStorage.getItem('token');
const apiClient = axios.create({
    baseURL: 'https://api.bachatkigaddi.com/api', 
    headers: {'Authorization': `Bearer ${token}`}
}); 
export const getList = async () => {
    try {
        const response = await apiClient.get('/getConsentData');
        return response.data.results;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};