import React, { useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; 
import OtpTimer from "otp-timer";

const Adminlogin = props => {
   // const baseURL = "https://dev-api.bachatkigaddi.com";
    const baseURL = "https://api.bachatkigaddi.com";
  //const baseURL = "http://localhost:5000";
  
    const navigate = useNavigate();
   
   const [mobileNumber,setMobile] = useState('');
   const [password,setPassword] = useState(''); 
   const pattern = new RegExp(/^\d{1,10}$/); 
   const handleSubmit = async(e)=>{ 
       e.preventDefault();   
       
       if(!pattern.test(mobileNumber))
       {
            alert('Mobile number is not valid');
            setMobile("");
            return;
       }
       
       if(password=="")
        {
             alert('Please enter password');
             setMobile("");
             return;
        }
       
    //    if(mobileNumber=="")
    //    {
         
    //      alert('Please enter Mobile Number'); 
    //      return
    //    }
       try
       { 
         
         
             
           await  axios
             .post(baseURL+"/api/AdminLogin",{ username :mobileNumber,password:password},{
               headers: { 
                 'content-type': 'application/json'
               }
             })
             .then((response) => {
                // setPost(response.data);
                console.log(response);
                if(response.data.status)
                {
                     //  alert('hi');
                       // localStorage.setItem('username', mobileNumber);
                        localStorage.setItem('token',response.data.data.token);
                        localStorage.setItem('id', response.data.data.id);
                        
                        
                       console.log(localStorage.getItem('token'));
                       
                       navigate('/admindashboard');
                       
                }
                else
                {
                     alert(response.data.results.msg);
                }
                 
             });
         
       }
       catch(error){
         
         alert(error);
         
         
       }
     
     
       
      // setIsOpen(false);
       
   }
    
 
  return (
        <>
        
        <div className="container"> 
<div className="row justify-content-center">

    <div className="col-xl-10 col-lg-12 col-md-9">

        <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-0">
                
                <div className="row">
                    {/* <div className="col-lg-6 d-none d-lg-block bg-login-image text-center"
                     style={{backgroundColor: "gray"}}><img src="./img/BajajChetak1.png"
                           style={{width:"100%"}} /></div> */}
                           <div className='col-md-8'>
                           <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel" >
                  <ol class="carousel-indicators">
                    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                  </ol>
                  <div class="carousel-inner" role="listbox">
                    <div class="carousel-item active">
                      <img class="d-block img-fluid" src="./img/BajajChetak1.png" alt="First slide" />
                      <div class="carousel-caption d-none d-md-block">
                        <div class="banner-text" style={{ width:"100%"}}>
                          {/* <h2 style={{ color:"black"}}>दैनिक भास्कर ई-बाइक योजना</h2> */}
                          {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation</p> */}
                        </div>
                      </div>
                    </div>
                    <div class="carousel-item">
                      <img class="d-block img-fluid" src="./img/iqube1.png" alt="secound slide" />
                      <div class="carousel-caption d-none d-md-block">
                        <div class="banner-text">
                          {/* <h2>This is Heaven</h2> */}
                          {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation</p> */}
                        </div>
                      </div>
                    </div>
                    <div class="carousel-item">
                      <img class="d-block img-fluid"src="./img/BajajChetak2.png"  alt="third slide" />
                      <div class="carousel-caption d-none d-md-block">
                        <div class="banner-text">
                          {/* <h2>This is Heaven</h2> */}
                          {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation</p> */}
                        </div>
                      </div>
                    </div>
                    <div class="carousel-item">
                      <img class="d-block img-fluid"src="./img/iqube2.png"  alt="forth slide" />
                      <div class="carousel-caption d-none d-md-block">
                        <div class="banner-text">
                          {/* <h2>This is Heaven</h2> */}
                          {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation</p> */}
                        </div>
                      </div>
                    </div>
                    {/* <div class="carousel-item">


                      <video autoPlay={true} src="https://mdbcdn.b-cdn.net/img/video/Tropical.mp4" width="750" height="480" controls loop muted   ></video>

                      <div class="carousel-caption d-none d-md-block">
                        <h5>First slide label</h5>
                        <p>
                          Nulla vitae elit libero, a pharetra augue mollis interdum.
                        </p>
                      </div>
                    </div> */}
                  </div>

                </div>
                            
                           </div>
                             
                    <div className="col-lg-4"> 
                        <div className="p-5">
                            <div className="">
                                <h1 className="h4 text-gray-900 mb-4">Admin Login</h1>
                            </div>
                            <form className="user" method="post">
                                <div className="form-group">
                                    <input type="number" name="mobile" className="form-control form-control-user"
                                        id="exampleInputEmail"  
                                        placeholder="Enter Mobile Number" value={mobileNumber} onChange={(e)=>setMobile(e.target.value)} max="10"  required  />
                                </div>
                                
                                <div className="form-group">
                                    <input type="password" name="password" className="form-control form-control-user"
                                        id="exampleInputEmail"  
                                        placeholder="Enter Password" value={password} onChange={(e)=>setPassword(e.target.value)}   required  />
                                </div>
                                
                                
                                <a   className="btn btn-primary btn-user btn-block" onClick={handleSubmit}>
                                    Login
                                </a>
                                
                                 
                            </form>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>

        </div>

        
        </>
  )
   
  
}

Adminlogin.propTypes = {}

export default Adminlogin