import React from 'react'
import {HashRouter as Router,Route,Link,Routes } from 'react-router-dom'  
import Login from './Login';
import Dashboard from './Dashboard';
import Consent from './Consent';
import Adminlogin from './Adminlogin';
import Admindashboard from './Admindashboard';
import Listconsentform from './Listconsentform';
import Consentcashuser from './Consentcashuser';
import Loginnew from './Loginnew';  

export default function Routing() {
  return (
    <>
    <Router> 
        <Routes>        
            {/* <Route path="/" element={<Login />}  ></Route>  */}
            <Route path="/" element={<Loginnew />}  ></Route> 
           
            <Route path="Dashboard" element={<Dashboard/>}></Route>
            <Route path="Consent" element={<Consent/>}></Route>
            <Route path="admin" element={<Adminlogin/>}></Route>
            <Route path="admindashboard" element={<Admindashboard/>}></Route>
            <Route path="listconsentform" element={<Listconsentform/>}></Route>  
            <Route path="Consentcashuser" element={<Consentcashuser/>}></Route> 
            
        </Routes> 
    </Router> 
    </>
  )
}
