import React from 'react'
import { Link } from 'react-router-dom'
function Nav() {
  return (
    <>
        <ul class="navbar-nav bg-gradient-warning sidebar sidebar-dark accordion" id="accordionSidebar" >

    
<a class="sidebar-brand d-flex align-items-center justify-content-center" href="index.html" style={{ color:"black" }}>
    <div class="sidebar-brand-icon rotate-n-15">
        
       {/* <i class="fas fa-motorcycle"></i> */}
    </div>
    <div class="sidebar-brand-text mx-3">EV Bike</div>
</a>


<hr class="sidebar-divider my-0" /> 


<li class="nav-item active" >
    <a class="nav-link" >
        <i class="fas fa-fw fa-tachometer-alt"></i>
        <span ><Link to="/Dashboard" style={{ color:"black" }}>Dashboard</Link></span></a>
</li>


<hr class="sidebar-divider"/>

{/* 
<div class="sidebar-heading" style={{ color:"black" }}>
    Interface
</div>


<li class="nav-item" >
    <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo"
        aria-expanded="true" aria-controls="collapseTwo">
        <i class="fas fa-fw fa-cog"></i>
        <span style={{ color:"black" }}>योजना </span>
    </a>
    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
        <div class="bg-white py-2 collapse-inner rounded">
             
            <a class="collapse-item" href="../HeroVidaV1PrBrochure.pdf" target="_blank" style={{ color:"black" }}>ई बाइक विवरण</a>
            <a class="collapse-item" href="../EVOfferLetter.docx" target="_blank" style={{ color:"black" }}>योजना विवरण</a>
        </div>
    </div>
</li> */}

{/*  
<li class="nav-item">
    <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseUtilities"
        aria-expanded="true" aria-controls="collapseUtilities">
        <i class="fas fa-fw fa-wrench"></i>
        <span>Utilities</span>
    </a>
    <div id="collapseUtilities" class="collapse" aria-labelledby="headingUtilities"
        data-parent="#accordionSidebar">
        <div class="bg-white py-2 collapse-inner rounded">
            <h6 class="collapse-header">Custom Utilities:</h6>
            <a class="collapse-item" href="#">A</a>
            <a class="collapse-item" href="#">B</a>
            <a class="collapse-item" href="#">C</a>
            <a class="collapse-item" href="#">D</a>
        </div>
    </div>
</li> */}
 

 
<hr class="sidebar-divider d-none d-md-block" />
<div class="text-center d-none d-md-inline">
    <button class="rounded-circle border-0" id="sidebarToggle"></button>
</div>


 

</ul>
    </>
  )
}

export default Nav