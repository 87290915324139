import React, { useEffect,useState } from 'react';
import { Link } from 'react-router-dom'
import Nav from './layout/Nav'
import Footer from './layout/Footer'
import Topnav from './layout/Topnav'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
  const Dashboard = props => {
    
    // const baseURL = "http://localhost:5000/api";
   
  //const baseURL = "https://dev-api.bachatkigaddi.com"; 
 const baseURL = "https://api.bachatkigaddi.com"; 
 
    if(localStorage.getItem('mobile')==null)
        {
            navigate('/');
          
        }
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [hasFetched, setHasFetched] = useState(false);
    const [error, setError] = useState(null);    
    const [user_type,setUsertype] = useState('');
    const [linkname,setLinkname] = useState('');
    
    useEffect(() => {
        const fetchData = async () => {
         
          
              if(!hasFetched){
                  setHasFetched(true); 
              try {
                //  alert('dsf');
                const agent_mobile = { agent_mobile: JSON.parse(localStorage.getItem('mobile')) };
                const response = await axios.post(baseURL+'/api/getAgentDetails_ByID', agent_mobile); 
      
                if (response) {
                   console.log('vikas');
                    console.log(response.data.results.res[0].user_type);
                    setUsertype(response.data.results.res[0].user_type);
                    if(response.data.results.res[0].user_type==1)
                    { 
                        setLinkname("/Consent");
                    }
                    else
                    {
                        setLinkname("/Consentcashuser");
                    }
                    
                }
              } catch (error) {
                setError(error.message);
              } finally {
                setLoading(false);
              }
      
              }
        };
        
      
        fetchData();
      }, [hasFetched]);
  return (
    <>
    
    <div id="wrapper"> 
      <Nav/>
 
<div id="content-wrapper" class="d-flex flex-column">

    
    <div id="content">

       
         <Topnav/>
         
        <div class="container-fluid"> 

            
            <div class="row"> 
                <div class="col-xl-3 col-md-6 mb-4">
                    <div class="card bg-primary  h-100 py-2">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col mr-2">
                                    <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                        
                                        
                                      <Link to={linkname}  style={{color:"white"}}>सहमति पत्र - इलेक्ट्रिक बाइक योजना /<br/> <br/> એમઓયુ - ઇલેક્ટ્રિક બાઇક યોજના</Link> </div>
                                     
                                </div>
                                <div class="col-auto">
                                    {/* <i class="fas fa-calendar fa-2x text-gray-300"></i> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

               
                <div class="col-xl-3 col-md-6 mb-4">
                    <div class="card bg-info    h-100 py-2">
                        <div className="card-body ">
                            <div class="row no-gutters align-items-center">
                                <div class="col mr-2">
                                    <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                    <a class="collapse-item"   style={{color:"white"}}>ई बाइक विवरण / ઇ બાઇક વિગતો</a>  
                                    <br/>
                                    <br/>
                                   <p> 
                                   {/* <a href="../BajajChetakBrochure.pdf" target="_blank" class="link-light" style={{fontSize:"14px",color:"white"}}>BAJAJ</a> &nbsp; */}
                                   <a href="../TVSiQube9_specsheet_v1Brochure.pdf" target="_blank" class="link-light" style={{fontSize:"14px",color:"white"}}>TVS</a>
                                    </p>
                                    </div>
                                    
                                </div>
                                <div class="col-auto">
                                    {/* <i class="fas fa-dollar-sign fa-2x text-gray-300"></i> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                
                <div class="col-xl-3 col-md-6 mb-4">
                    <div class="card bg-danger h-100 py-2">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col mr-2">
                                    <div class="text-xs font-weight-bold text-info text-uppercase mb-1"><a class="collapse-item" href="../EV_offer_LetterHindi.pdf" target="_blank" style={{color:"white"}}>योजना विवरण</a><br/>
                                    <br/>
                                    <a class="collapse-item" href="../EV_Offer_Letter_Gujarati.pdf" target="_blank" style={{color:"white"}}>યોજના વિગતો</a>
                                    </div>
                                    <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                    
                                    </div>
                                </div>
                                <div class="col-auto">
                                    {/* <i class="fas fa-clipboard-list fa-2x text-gray-300"></i> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="col-xl-3 col-md-6 mb-4">
                    <div class="card bg-info h-100 py-2">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col mr-2">
                                    <div class="text-xs font-weight-bold text-info text-uppercase mb-1">
                                      {/* <a class="collapse-item" href="../BajajChetakDealers.pdf" target="_blank" style={{color:"white"}}>डीलर (BAJAJ)</a><br/> */}
                                    <br/>
                                    <a class="collapse-item" href="../TVSiQubeDealer.pdf" target="_blank" style={{color:"white"}}>डीलर (TVS)</a>
                                    </div>
                                    <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                    
                                    </div>
                                </div>
                                <div class="col-auto">
                                    {/* <i class="fas fa-clipboard-list fa-2x text-gray-300"></i> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                
                {/* <div class="col-xl-3 col-md-6 mb-4">
                    <div class="card border-left-success shadow h-100 py-2">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col mr-2">
                                    <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                    <a class="collapse-item" href="../evbike_images.pdf" target="_blank" >ई बाइक फोटो</a> 
                                    </div>
                                    
                                </div>
                                <div class="col-auto">
                                     
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
 
            </div>
            
            
            <div className="row col-md-12">
            <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel"  style={{width:"500px",height:"300px"}} >
                  <ol class="carousel-indicators">
                    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                    
                  </ol>
                  <div class="carousel-inner" role="listbox" >
                    {/* <div class="carousel-item active">
                      <img class="d-block img-fluid" src="./img/BajajChetak1.png" alt="First slide" />
                      <div class="carousel-caption d-none d-md-block">
                        <div class="banner-text" style={{ width:"100%"}}>
                          
                        </div>
                      </div>
                    </div>
                    <div class="carousel-item">
                      <img class="d-block img-fluid" src="./img/BajajChetak2.png" alt="secound slide" />
                      <div class="carousel-caption d-none d-md-block">
                        <div class="banner-text">
                          
                        </div>
                      </div>
                    </div>
                    <div class="carousel-item">
                      <img class="d-block img-fluid"src="./img/BajajChetak3.png"  alt="third slide" />
                      <div class="carousel-caption d-none d-md-block">
                        <div class="banner-text">
                         
                        </div>
                      </div>
                    </div> */}
                    <div class="carousel-item active">
                      <img class="d-block img-fluid"src="./img/iqube1.png"  alt="third slide" />
                      <div class="carousel-caption d-none d-md-block">
                        <div class="banner-text">
                           
                        </div>
                      </div>
                    </div>
                    <div class="carousel-item">
                      <img class="d-block img-fluid"src="./img/iqube2.png"  alt="third slide" />
                      <div class="carousel-caption d-none d-md-block">
                        <div class="banner-text">
                          
                        </div>
                      </div>
                    </div>
                    <div class="carousel-item">
                      <img class="d-block img-fluid"src="./img/iqube3.png"  alt="third slide" />
                      <div class="carousel-caption d-none d-md-block">
                        <div class="banner-text">
                         
                        </div>
                      </div>
                    </div>
                     
                    {/* <div class="carousel-item">


                      <video autoPlay={true} src="https://mdbcdn.b-cdn.net/img/video/Tropical.mp4" width="750" height="480" controls loop muted   ></video>

                      <div class="carousel-caption d-none d-md-block">
                        <h5>First slide label</h5>
                        <p>
                          Nulla vitae elit libero, a pharetra augue mollis interdum.
                        </p>
                      </div>
                    </div> */}
                  </div>

                </div>
            </div>
            
            

         
         

        </div>
      

    </div> 
 
    <Footer/>

</div> 
</div>
    
    </>
  )
}
 
export default Dashboard