import React from 'react'; 
import { Route, Router, Routes } from 'react-router-dom';
import Routing from './components/Routing';

function App() {
  
  return (
    <>
     <Routing />
      
    </>
  );
}

export default App;
