import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import axios from 'axios';
import { redirect, useNavigate } from 'react-router-dom';  
const Loginnew = props => {

  const navigate = useNavigate();

  const [mobileNumber, setMobile] = useState('');
  const [isOpen, setIsOpen] = useState(true);
  const [otp, setOtp] = useState('');
  const [items, setItems] = useState([]);
  const pattern = new RegExp(/^\d{1,10}$/);
  const patternotp = new RegExp(/^\d{1,3}$/);
  const [message, setMessage] = useState('');
  
 // const baseURL = "http://localhost:5000";
   
 //const baseURL = "https://dev-api.bachatkigaddi.com";
 const baseURL = "https://api.bachatkigaddi.com";
 

  const handleInputChange = (event) => {
    const value = event.target.value;

    if (/^\d*$/.test(value)) {
      setMobile(value);

      // Regular expression to match exactly 10 digits
      const regex = /^\d{10}$/;
      if (value.length === 0 || regex.test(value)) {
        setMessage(value.length === 10 ? '' : 'please enter mobi');
      } else {
        setMessage('Mobile number must be exactly 10 digits.');
      }
    } else {
      setMessage('Only digits are allowed.');
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (mobileNumber == "") {
      setMessage('Please enter mobile number');
      return
    }

    //    if(mobileNumber=="")
    //    {

    //      alert('Please enter Mobile Number'); 
    //      return
    //    }
    try {



      await axios
        .post(baseURL+"/api/getAgentDetails", { agent_mobile_number: mobileNumber }, {
          headers: {
            'content-type': 'application/json'
          }
        })
        .then((response) => {
          // setPost(response.data);
          console.log(response.data.results);
          if (response.data.results.status) {
            setIsOpen(false);
            setOtp(response.data.results.otp);
            localStorage.setItem('mobile', mobileNumber);
            localStorage.setItem('uid', JSON.stringify(response.data.results.res[0]['zvt_portal_reg_id']));

            localStorage.setItem('flag_mobile', JSON.stringify(response.data.results.res[0]['mobile']));

            console.log(JSON.parse(localStorage.getItem('mobile')));


          }
          else {
            alert(response.data.results.msg);
          }

        });

    }
    catch (error) {

      alert(error);


    }



    // setIsOpen(false);

  }


  const handleOtp = async (e) => {

    e.preventDefault();

    // alert(localStorage.getItem('mobile'));

    //return false;

    if (patternotp.test(otp)) {
      alert('OTP is not valid');
      return;
    }
    try {



      await axios
        .post(baseURL+"/api/OTP_Verify", { otp_value: otp, agent_mobile: localStorage.getItem('mobile') }, {
          headers: {
            'content-type': 'application/json'
          }
        })
        .then((response) => {
          // setPost(response.data);
          console.log(response.data.results);
          if (response.data.results.status) {

            //  alert(response.data.results.msg);
            navigate("/Dashboard");
          }
          else {
            alert(response.data.results.msg);
            localStorage.setItem('flag_mobile', '');
          }

        });

    }
    catch (error) {

      alert(error);


    }

  }

  const handleResend = async (e) => {

   // alert(localStorage.getItem('mobile'));
    // return;
    try {

      const resenddata = { agent_mobile: localStorage.getItem('mobile'), uid: localStorage.getItem('uid') }

      await axios
        .post(baseURL+"/api/Resend_OTP", JSON.stringify(resenddata), {
          headers: {
            'content-type': 'application/json'
          }
        })
        .then((response) => {
          // setPost(response.data);
          console.log(response.data.results);
          if (response.data.results.status) {

            alert(response.data.results.msg);
            // navigate("/Dashboard");
          }
          else {
            alert(response.data.results.msg);
            localStorage.setItem('flag_mobile', '');
          }

        });

    }
    catch (error) {

      alert(error);


    }

  }
  
  const backtologin = ()=>{
    
    
    setIsOpen(true);
    navigate("/");
    
  }


  if (isOpen)
    return (
      <>
  
        <section className="login-block" style={{ padding:"6px 0",ackground:"#f89c1c",background:"-webkit-linear-gradient(to bottom, #FFB88C, #DE6262)",background:"linear-gradient(to bottom, #f5cc93, #f89c1c)"}}>
          <div className="container" style={{ boxShadow: "7px 7px 0px rgba(0, 0, 0, 0.1)" }}>
            <div className="row" style={{ marginRight: "-1.75rem" }}>
              <div className="col-md-4 login-sec text-center" style={{ padding:"39px 30px"}}>
                <img className="img img-fluid" src="./dbev.png" alt="description" style={{ width: "260px" }} />

                <h2 className="text-center" style={{ fontSize:"23px",  marginTop:"5px"}}>Login / लॉग इन / લોગીન</h2>

                <form className="login-form  text-left" method="post">
                  <div className="form-group">
                    <label for="exampleInputEmail1" style={{ fontSize:"15px"}}>Mobile Number / मोबाइल नंबर / મોબાઈલ નંબર</label>
                    <input type="number" name="mobile" className="form-control form-control-user"
                      id="exampleInputEmail"
                      placeholder="Enter Mobile Number" value={mobileNumber} onChange={handleInputChange} max="10" required />
                    {message && (
                      <p style={{ color: message === 'Mobile number is valid.' ? 'green' : 'red' }}>
                        {message}
                      </p>
                    )}

                  </div>



                  <div className="col-md-12 ">
                    <div className='row' style={{ float:"right" }} >                      
                      <a className="btn btn-primary " onClick={handleSubmit}>
                        SEND OTP
                      </a>
                    </div>
                  </div>
                  
                  <br/>
                    
                </form>
                <br/>
                
              </div>
              
              <div class="col-md-8 banner-sec">
                <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel" >
                  <ol class="carousel-indicators">
                    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                     
                  </ol>
                  <div class="carousel-inner" role="listbox">
                    {/* <div class="carousel-item active">
                      <img class="d-block img-fluid" src="./img/BajajChetak1.png" alt="First slide" />
                      <div class="carousel-caption d-none d-md-block">
                        <div class="banner-text" style={{ width:"100%"}}>
                         
                        </div>
                      </div>
                    </div> */}
                    <div class="carousel-item active">
                      <img class="d-block img-fluid" src="./img/iqube1.png" alt="secound slide" />
                      <div class="carousel-caption d-none d-md-block">
                        <div class="banner-text">
                           
                        </div>
                      </div>
                    </div>
                    {/* <div class="carousel-item">
                      <img class="d-block img-fluid"src="./img/BajajChetak2.png"  alt="third slide" />
                      <div class="carousel-caption d-none d-md-block">
                        <div class="banner-text">
                          
                        </div>
                      </div>
                    </div> */}
                    <div class="carousel-item">
                      <img class="d-block img-fluid"src="./img/iqube2.png"  alt="forth slide" />
                      <div class="carousel-caption d-none d-md-block">
                        <div class="banner-text">
                           
                        </div>
                      </div>
                    </div>
                    {/* <div class="carousel-item">


                      <video autoPlay={true} src="https://mdbcdn.b-cdn.net/img/video/Tropical.mp4" width="750" height="480" controls loop muted   ></video>

                      <div class="carousel-caption d-none d-md-block">
                        <h5>First slide label</h5>
                        <p>
                          Nulla vitae elit libero, a pharetra augue mollis interdum.
                        </p>
                      </div>
                    </div> */}
                  </div>

                </div>
              </div>
              
                     
                       
            </div>
            
          </div>
          
          <div className='container' style={{ boxShadow: "7px 4px 0px rgba(0, 0, 0, 0.1)",marginTop:"12px",marginBottom:"4px",padding:"5px",backgroundColor:"antiquewhite" }}>
            <div className='text-center'>
                  
                  <span style={{color:"red"}}><b>Helpdesk No : </b></span> &nbsp;&nbsp;
                  <span  style={{color:"black"}}><b>&nbsp;&nbsp;&nbsp; Hindi - </b>0755-4730039, 0755-4733080</span> 
                  <span style={{color:"black"}}><b> &nbsp;&nbsp;&nbsp; Gujrati - </b>+91-7435873217</span>  
                  </div>
            </div>

        </section>
      </>
    )
  return <>
    <section className="login-block" style={{ padding:"6px 0",ackground:"#f89c1c",background:"-webkit-linear-gradient(to bottom, #FFB88C, #DE6262)",background:"linear-gradient(to bottom, #f5cc93, #f89c1c)"}}>
          <div className="container" style={{ boxShadow: "7px 7px 0px rgba(0, 0, 0, 0.1)" }}>
            <div className="row" style={{ marginRight: "-1.75rem" }}>
              <div className="col-md-4 login-sec text-center" style={{ padding:"37px 30px"}}>
                <img className="img img-fluid" src="./dbev.png" alt="description" style={{ width: "260px" }} />

                <h2 className="text-center" style={{ fontSize:"23px",  marginTop:"5px"}}></h2>

                <form className="login-form text-left" method="post">
                  <div className="form-group">
                    <label for="exampleInputEmail1" className="text-uppercase">Enter OTP / ओटीपी दर्ज करें / OTP દાખલ કરો
                    </label>
                    <input type="number" className="form-control form-control-user"
                      id="exampleInputEmail" value={otp} onChange={(e) => setOtp(e.target.value)}
                      placeholder="OTP" required />
    
                  </div>
    
    
    
                  <div className="form-check">
    
                    <a className="btn btn-primary btn-user btn-block float-right" onClick={handleOtp}>
                      Verify
                    </a>
                    <a className="btn btn-info btn-user btn-block float-right" onClick={handleResend}>
                      Resend
                    </a>
                    
                      <a class="nav-link" >

                      <span ><Link   style={{ color:"black",float:"right" }} onClick={backtologin}>Back to Login</Link></span></a>
                  </div>
    
                </form>
                

              </div>
              <div class="col-md-8 banner-sec">
                <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel" >
                  <ol class="carousel-indicators">
                    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                  </ol>
                  <div class="carousel-inner" role="listbox">
                    {/* <div class="carousel-item active">
                      <img class="d-block img-fluid" src="./img/BajajChetak1.png" alt="First slide" />
                      <div class="carousel-caption d-none d-md-block">
                        <div class="banner-text" style={{ width:"100%"}}>
                           
                        </div>
                      </div>
                    </div> */}
                    <div class="carousel-item">
                      <img class="d-block img-fluid" src="./img/iqube1.png" alt="secound slide" />
                      <div class="carousel-caption d-none d-md-block">
                        <div class="banner-text">
                          
                        </div>
                      </div>
                    </div>
                    {/* <div class="carousel-item">
                      <img class="d-block img-fluid"src="./img/BajajChetak2.png"  alt="third slide" />
                      <div class="carousel-caption d-none d-md-block">
                        <div class="banner-text">
                          
                        </div>
                      </div>
                    </div> */}
                    <div class="carousel-item">
                      <img class="d-block img-fluid"src="./img/iqube2.png"  alt="forth slide" />
                      <div class="carousel-caption d-none d-md-block">
                        <div class="banner-text">
                          
                        </div>
                      </div>
                    </div>
                    {/* <div class="carousel-item">


                      <video autoPlay={true} src="https://mdbcdn.b-cdn.net/img/video/Tropical.mp4" width="750" height="480" controls loop muted   ></video>

                      <div class="carousel-caption d-none d-md-block">
                        <h5>First slide label</h5>
                        <p>
                          Nulla vitae elit libero, a pharetra augue mollis interdum.
                        </p>
                      </div>
                    </div> */}
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className='container' style={{ boxShadow: "7px 4px 0px rgba(0, 0, 0, 0.1)",marginTop:"12px",marginBottom:"4px",padding:"5px",backgroundColor:"antiquewhite" }}>
            <div className='text-center'>
                  
                  <span style={{color:"red"}}><b>Helpdesk No : </b></span> &nbsp;&nbsp;
                  <span  style={{color:"black"}}><b>&nbsp;&nbsp;&nbsp; Hindi - </b>0755-4730039, 0755-4733080</span> 
                  <span style={{color:"black"}}><b> &nbsp;&nbsp;&nbsp; Gujrati - </b>+91-7435873217</span>  
                  </div>
            </div>
        </section>
  </>

}

Loginnew.propTypes = {}

export default Loginnew