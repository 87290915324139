import React, { useEffect,useState } from 'react';
import { Link } from 'react-router-dom';
import Nav from './layout/Nav';
import Footer from './layout/Footer';
import Topnav from './layout/Topnav';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'; 

 
  const Consent = props => {
    const { t, i18n } = useTranslation();
    const changeLanguage = lng => { 
      
      i18n.changeLanguage(lng); 
      return;
    };
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [agentData, setData] = useState(null);    
    const[tempagenData,setTempagentdata] = useState(); 
    const [hasFetched, setHasFetched] = useState(false);    
    const [address,setAddress] = useState('');
    const [uid,setUID] = useState('');
    const [pancard,setPancard] = useState('');
    const [bankname,setBankname] = useState('');
    const [bankac,setBankAc] = useState('');
    const [ifsc,setIfsc] = useState('');
    const [edition,setEdition] =useState('');
    const [disabled,setDisabled] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [isChecked, setIsChecked] = useState(false);
    const [isCheckboxValid, setIsCheckboxValid] = useState(true);
    const [vehicle,setVehicle] = useState('');
    const [hi,setHi] = useState('HI');
    const [gj,setGj] = useState('GJ');
    const [subbtn,setsubmitbtn] = useState(0);
    const [agree,setAgree] = useState(0);
    const [langag,setfontAll] = useState('testing');
    const [selectedOption, setSelectedOption] = useState('');
    const [error, setError] = useState('');
    const [errornew, setErrornew] = useState('');
    
    const [state, setStatename] = useState('');
    
   // const baseURL = "http://localhost:5000/api";
   
    //const baseURL = "https://dev-api.bachatkigaddi.com";
    const baseURL = "https://api.bachatkigaddi.com";
    // const handleLanguage = (event) => {
    //    alert(event.target.value);
       
    //    if(event.target.value=='HI')
    //    {
    //     setfontAll('विकास');
    //    }
    //    else if(event.target.value=='GJ')
    //    {
    //     setfontAll('વિકાસ');
    //    }
     
      
    // };
    const handleChange = (event) => {
      setSelectedOption(event.target.value);
      setErrornew(''); // Clear error when user selects an option
      setVehicle(event.target.value);
      return;
  };
useEffect(() => {
  const fetchData = async () => {
   
    
        if(!hasFetched){
            setHasFetched(true); 
        try {
            
          const agent_mobile = { agent_mobile: JSON.parse(localStorage.getItem('mobile')) };
          const response = await axios.post(baseURL+'/api/getAgentDetails_ByID', agent_mobile); 

          if (response) {
    //    alert(JSON.stringify(response.data.results.res[0]));
            setData(response.data.results.res[0]);
            setTempagentdata(response.data.results.res[0]);
            
            if(response.data.results.res[0].edition)
              {
                setEdition(response.data.results.res[0].edition);
                setDisabled(true);
              }
            
            if(response.data.results.res[0].address)
            {
              setAddress(response.data.results.res[0].address);
              setDisabled(true);
            }
            if(response.data.results.res[0].bankname)
            {
              setBankname(response.data.results.res[0].bankname);
              setDisabled(true);
            }
            if(response.data.results.res[0].uid)
            {
              setUID(response.data.results.res[0].uid);
              setDisabled(true);
            }
            if(response.data.results.res[0].pancard)
            {
              setPancard(response.data.results.res[0].pancard);
              setDisabled(true);
              setsubmitbtn(1);
              setAgree(1);
            }
            if(response.data.results.res[0].bankac)
            {
              setBankAc(response.data.results.res[0].bankac);
              setDisabled(true);
            }
            if(response.data.results.res[0].ifsc)
            {
              setIfsc(response.data.results.res[0].ifsc);
              setDisabled(true);
            }
            if(response.data.results.res[0].vehicle)
              {
              
                setSelectedOption(response.data.results.res[0].vehicle);
              }
              console.log(response.data.results.res[0]);
          }
        } catch (error) {
          setError(error.message);
        } finally {
          setLoading(false);
        }

        }
  };
  

  fetchData();
}, [hasFetched]);

const handleCheckboxChange = (e) => {
  setIsChecked(e.target.checked);
  setIsCheckboxValid(true); // Reset validation message when user interacts with checkbox
};
const handleSubmit = async(e)=>{
  e.preventDefault();   
  
  
  
  let regex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
   
 let regexuid = new RegExp(/^\d{6}$/); 
  
 // let regexifsc = new RegExp(/^\d{11}$/);
 
  const ifscRegex = new RegExp(/^[A-Z]{4}0[A-Z0-9]{6}$/);
  
  let bankacRegex = new RegExp(/^\d{8,17}$/);    // bankaccount
  
  const bankNameRegex = new RegExp(/^[A-Za-z\s.,'-]+$/);    // bank name ;
  
 
  
  const AgentPayload = {
    agent_name : tempagenData['agent_name'],
    name1 :  tempagenData['name1'],
    city1 : tempagenData['city1'],
    edition : edition,
    partner : tempagenData['partner'],
    address : address,
    mobile : tempagenData['mobile'],
    uid : uid,
    pancard : pancard,
    bankname : bankname,
    bankac : bankac,
    ifsc : ifsc,
    vehicle:vehicle,
    agentid: tempagenData['zvt_portal_reg_id']
  } 
  
  
  if (edition == "")  
    {
      alert('Please enter edition');
      return ;
    }
  if (address == "")  
    {
      alert('Please enter address');
      return ;
    }
    if (regexuid.test(uid) == false)
      {
          alert('Please enter valid pincode');
          return ;
      } 
  
   if (pancard == "")  
    {
      alert('Please enter pancard');
      return ;
    }
    
    if (regex.test(pancard) == false)
    {
        alert('Please enter valid pancard');
        return ;
    } 
    
    
  //  if (uid == "")  
  //   {
  //     alert('Please enter Aadhar Number');
  //     return ;
  //   }
    
    if (regexuid.test(uid) == false)
    {
        alert('Please enter valid Aadhar Number');
        return ;
    } 
    
    // if (bankname == "")  
    //   {
    //     alert('Please enter bank name');
    //     return ;
    //   }
    if (bankNameRegex.test(bankname) == false)
      {
          alert('Please enter valid bank name');
          return ;
      } 
      
      // if (bankac == "")  
      //   {
      //     alert('Please enter bank account');
      //     return ;
      //   }
      
        if (bankacRegex.test(bankac) == false)
          {
              alert('Please enter valid bank account');
              return ;
          } 
          
        
        if (ifscRegex.test(ifsc) == false)
          {
              alert('Please enter valid ifsc code');
              return ;
          }
          
         
          if (!selectedOption) {
            setErrornew('Please select an option.');
            alert('Please choose any one vehile');
            return ;
        }  
        
          if (!isChecked) {
            setIsCheckboxValid(false);
            
            alert('Please agree to the terms and conditions ');
            return;
        }
        
        
        
        
        try
        { 
          
         
          setsubmitbtn(1);
        
              
            await  axios
              .post(baseURL+"/api/SubmitConsentForm",JSON.stringify(AgentPayload),{
                headers: { 
                  'content-type': 'application/json'
                }
              })
              .then((response) => {
                // setPost(response.data);
                console.log(response.data.results);
                if(response.data.results.status)
                {
                       
                    alert("Details updated successfully");
                    let msgtxt = "";
                    let indiaDltContentTemplateId = "";
                    let indiaDltPrincipalEntityId = "";
                      if(agentData['state']=="MP" || agentData['state']=="Rajasthan")
                      {
                          msgtxt = "श्रीमान "+tempagenData['agent_name']+" आपके द्वारा इलेक्ट्रिक बाइक योजना में  सम्मिलित होने के लिए हमें आपकी सहमति प्राप्त हो गई है संपर्क करने के लिए बाइक डीलर व बैंक की सूचना आपको sms अथवा whatsapp द्वारा जल्द मिल जाएगी । -Bhaskar Group";
                          indiaDltContentTemplateId = "1107172259416288979";
                          indiaDltPrincipalEntityId = "1101693520000011534"; 
                      
                     }
                    else
                    {
                      msgtxt = "સાહેબ "+tempagenData['agent_name']+" ઇલેક્ટ્રિક બાઇક યોજનામાં જોડાવા માટે અમે તમારી સંમતિ મળી છે સંપર્ક કરવા માટે, તમને ટૂંક સમયમાં બાઇક ડીલર અને બેંક વિશે SMS અથવા WhatsApp દ્વારા માહિતી મળશે. -Bhaskar Group";
                          indiaDltContentTemplateId = "1107172259421962557";
                          indiaDltPrincipalEntityId = "1101693520000011534";
                    }
                    
                    const infobipUsername = "corpbooking";
                    const infobipPassword = "corpbooking@123&";
                    const  from = "BHASKR";
                    
                    const toNumbers = '91' + tempagenData['mobile'];
                    
                    const infobipBaseURL = 'https://api.infobip.com/sms/1/text/query.json?username='+infobipUsername+'&password='+infobipPassword+'&to='+toNumbers+'&text='+msgtxt+'&from='+from+'&indiaDltPrincipalEntityId='+indiaDltPrincipalEntityId+'&indiaDltContentTemplateId='+indiaDltContentTemplateId+'';
                    
                    axios.get(infobipBaseURL)
                    .then(response => {
                      console.log('Message sent:', response.data);
                    })
                    .catch(error => {
                      console.error('Error sending message:', error.response ? error.response.data : error.message);
                    });
                  
                    navigate("/Dashboard");
                        
                }
                else
                {
                      alert(response.data.results.msg);
                      setsubmitbtn(0);
                }
                  
              });
          
        }
        catch(error){
          
          alert(error);
          setsubmitbtn(0);
          
        }
}
 
// alert(localStorage.getItem('mobile'));

  if(localStorage.getItem('mobile')==null)
  {
      navigate('/');
    
  }
      if (loading) {
        return <div>Loading...</div>;
      }
    
      if (error) {
        return <div>Error: {error}</div>;
      }
   
  return (
    <>
    
    <div id="wrapper"> 
      <Nav/>
 
<div id="content-wrapper" class="d-flex flex-column">

    
    <div id="content">

       
         <Topnav/>
         
        <div class="container-fluid"> 

            
        <div class="row">

               
            <div class="col-xl-12 col-lg-12">
                <div class="card shadow mb-4">
                
                     
                    <form method='post'>
                   
                              <section>
  <div class="container py-2" style={{boxShadow:"0px 0px 0px 0" }}>
   
  
    <div class="row">  
      <div class="col-md-12">
        <p class="h3 mb-3" style={{backgroundColor:"#ffedaf",padding:"8px",color:"black",borderRadius:"3px"}}> सहमति पत्र - इलेक्ट्रिक बाइक योजना / એમઓયુ - ઇલેક્ટ્રિક બાઇક યોજના </p>
      </div>
       
      <div class="col-lg-3">
     
        <div class="card mb-4">
          <div class="card-body  text-center" style={{backgroundColor:"#f8f4f4", color:"black"}}>
            {/* <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp" alt="avatar"
              class="rounded-circle img-fluid" style={{width:"150px"}}/> */}
            <h5 class="my-3">{agentData['agent_name']}</h5>
            <p class="text-muted mb-1">Agency: {agentData['name1']}</p>
            <p class="text-muted mb-4">City:{agentData['city1']}, Mobile :  {agentData['mobile']}</p>
            <div class="d-flex justify-content-center mb-2">
               Code : {agentData['partner']}
              
            </div>
          </div>
        </div>
        
      </div>
      <div class="col-lg-9">
        <div class="card mb-4">
         
          <div class="card-body" style={{backgroundColor:"#f8f4f4",color:"black"}}>
            <div class="row mb-1">
              {/* <div class="col-sm-2">
                
              </div> */}
              <div class="col-sm-6">
              <label class="mb-0">DB edition का नाम/DB આવૃત્તિ નામ</label>
                <p class="text-muted mb-0"><input type="text" class="form-control" value={edition}  onChange={(e)=>setEdition(e.target.value)} disabled={disabled}/></p>
              </div>
              {/* <div class="col-sm-2">
               
              </div> */}
              <div class="col-sm-6">
              <label class="mb-0">पूरा पता/પૂરું સરનામું</label>
                <p class="text-muted mb-0"><input type="text" class="form-control" value={address} onChange={(e)=>setAddress(e.target.value)}  disabled={disabled} /></p>
              </div>
            </div>
            {/* <hr/> */}
            <div class="row mb-1">
              {/* <div class="col-sm-2">
                
              </div> */}
              <div class="col-sm-6">
              <label class="mb-0">पिनकोड/પિનકોડે </label>
                  <p class="text-muted mb-0">
                    <input type="text" class="form-control" value={uid} onChange={(e)=>setUID(e.target.value)} disabled={disabled} />
                  </p>
              </div>
              
              {/* <div class="col-sm-2">
                
              </div> */}
              <div class="col-sm-6">
              <label class="mb-0">पैन कार्ड नंबर/પાનકાર્ડ નંબર</label>
                  <p class="text-muted mb-0">
                  <input type="text" class="form-control" value={pancard} onChange={(e)=>setPancard(e.target.value.toUpperCase())} disabled={disabled} />
                  </p>
              </div>
              
            </div>
            {/* <hr/> */}
            
            
            <div class="row mb-1">
              {/* <div class="col-sm-2">
                
              </div> */}
              <div class="col-sm-6">
              <label class="mb-0">बैंक का नाम/બેંકનું નામ</label>
                  <p class="text-muted mb-0">
                  <input type="text" class="form-control" value={bankname} onChange={(e)=>setBankname(e.target.value)}  disabled={disabled} />
                  </p>
              </div>
              
              {/* <div class="col-sm-2">
                
              </div> */}
              <div class="col-sm-6">
              <label class="mb-0">बैंक अकाउंट नंबर/બેંક એકાઉન્ટ નંબર</label>
                  <p class="text-muted mb-0">
                  <input type="text" class="form-control" value={bankac} onChange={(e)=>setBankAc(e.target.value)} disabled={disabled}/>
                  </p>
              </div>
              
            </div>
            {/* <hr/> */}
            <div class="row mb-1">
              {/* <div class="col-sm-2">
                
              </div> */}
         
              <div class="col-sm-6">
              <label class="mb-0">बैंक IFSC/IFSC કોડ</label>
                <p class="text-muted mb-0"><input type="text" class="form-control" value={ifsc} onChange={(e)=>setIfsc(e.target.value.toUpperCase())}  disabled={disabled} /></p>
                <p>(Ex- ABCD0123456)</p>
              </div>
              <div class="col-sm-6">
        <label class="mb-0">Interested In</label>
        <p class="text-muted mb-0">
            <label class="radio-container">
                TVS &nbsp;
                <input type="radio"  name="interest"
                                value="TVS"
                                checked={selectedOption === 'TVS'}
                                onChange={handleChange} disabled={disabled} />&nbsp;
                <span class="radio-checkmark" ></span>
            </label>
            {/* <label class="radio-container">
                BAJAJ&nbsp;
                <input type="radio"  name="interest"
                                value="BAJAJ"
                                checked={selectedOption === 'BAJAJ'}
                                onChange={handleChange}  disabled={disabled} />&nbsp;
                <span class="radio-checkmark"></span>
            </label> */}
        </p>
        {errornew && <p className="error-message" style={{color:"red"}}>{errornew}</p>}
    </div>
            </div> 
            <div class="row mb-1">
              <div class="col-sm-12">
                {/* <p class="mb-0"> <p> <input type="checkbox"   checked={isChecked}
                        onChange={handleCheckboxChange} /> मैं दैनिक भास्कर द्वारा बताए गए “इलेक्ट्रिक बाइक योजना” में शामिल होना चाहता हूं एवं इसके नियम
                        एवं शर्तों से सहमत हूं |/(હું દૈનિક ભાસ્કર દ્વારા ઉલ્લેખિત “ઇલેક્ટ્રિક બાઇક યોજના”માં જોડાવા માંગુ છું.)</p>  </p> */}
                        
                  {agree == 0 && (
                      <p class="mb-0"> <p> <input type="checkbox"   checked={isChecked}
                      onChange={handleCheckboxChange} /> मैं दैनिक भास्कर द्वारा बताए गए “इलेक्ट्रिक बाइक योजना” में शामिल होना चाहता हूं एवं इसके नियम
                      एवं शर्तों से सहमत हूं |/(હું દૈનિક ભાસ્કર દ્વારા ઉલ્લેખિત “ઇલેક્ટ્રિક બાઇક યોજના”માં જોડાવા માંગુ છું.)</p>  </p>
                  )}
              </div>
              <div class="col-sm-3">
                
                {/* <p class="text-muted mb-0"> <button class="btn btn-primary" disabled={disabled} onClick={handleSubmit} >Submit</button></p> */}
                  {subbtn == 0 && (
                    <p className="text-muted mb-0">
                    <button
                    className="btn btn-primary"
                    disabled={disabled}
                    onClick={handleSubmit}
                    >
                    Submit
                    </button>
                    </p>
                  )}
              </div>
            </div>
            
          </div>
        </div>
        
      </div>
    </div>
  </div>
</section>
                    </form>
                    
                
                    
                </div>
            </div>
 
        </div>
        
            

        
         

        </div>
      

    </div> 
 
    <Footer/>

</div> 
</div>
    
    </>
  )
}
 
export default Consent