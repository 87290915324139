import React from 'react'
import { useNavigate } from 'react-router-dom';

function Topnav() {
    const navigate = useNavigate();
    const handleLogout = (e)=>{
        e.preventDefault();  
        //alert('hi');
        localStorage.removeItem("mobile");
        localStorage.removeItem("id");
        navigate('/');
        return;
    }
  return (
    <>
    <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

             
<button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
    <i class="fa fa-bars"></i>
</button>

 
<ul class="navbar-nav ml-auto">

    

    <div class="topbar-divider d-none d-sm-block"></div>

   
    <li class="nav-item dropdown no-arrow">
        <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span class="mr-2 d-none d-lg-inline text-gray-600 small">EV Bike</span>
            {/* <img class="img-profile rounded-circle"
                src="../img/undraw_profile.svg" /> */}
        </a>
         
        <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
            aria-labelledby="userDropdown">
             
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal" onClick={handleLogout}>
                <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                Logout
            </a>
        </div>
    </li>

</ul>

</nav>
    </>
  )
}

export default Topnav