import React, { useEffect,useState,useRef} from 'react';
import { Link } from 'react-router-dom' 
import Topnavadmin from './adminlayout/Topnavadmin'
import Navadmin from './adminlayout/Navadmin'
import Footeradmin from './adminlayout/Footeradmin'
import {getList} from '../services/list'
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import Pagination from './Pagination';
import { useNavigate } from 'react-router-dom';  
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column"; 
import { InputText } from 'primereact/inputtext';
import '../List.css'
function Listconsentform() {
    
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    global: { value: '', matchMode: 'contains' },
    partner: { value: '', matchMode: 'contains' },
    agent_name: { value: '', matchMode: 'contains' },
    vehicle: { value: '', matchMode: 'contains' },
    mobile: { value: '', matchMode: 'contains' },
    pancard: { value: '', matchMode: 'contains' }
    
});
    const [list, setList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(500);
    const dt = useRef(null);
    const [ival,setIval] = useState(1);
    let i =0;
    
     
  useEffect(() => {
    let mounted = true;
    getList()
      .then(items => {
        if(mounted) {
          setList(items.res)
          console.log(items.res);
          
        }
      })
    return () => mounted = false;
  }, [])
  const exportto = () => {
    dt.current.exportCSV();
  }
  const header = <div><div style={{textAlign:'left'}}> Total  : {list ? list.length : 0}</div><div style={{textAlign:'right'}}><button type="button" className='btn btn-sm btn-primary' icon="pi pi-external-link" iconPos="left" label="CSV" style={{textAlign:"right"}} onClick={exportto}>Export</button></div></div>;
  




  return (
    <>
         <div id="wrapper"> 
      <Navadmin/>
 
<div id="content-wrapper" class="d-flex flex-column">

    
    <div id="content">

       
         <Topnavadmin/>
         
        <div class="container-fluid"> 

        <div class="d-sm-flex align-items-center justify-content-between mb-4">
                        
                        
                    </div>
         
                   
         
                    
                    <div className="card text-bg-primary shadow mb-4">
                        <div class="card-header py-3">
                            <h6 class="m-0 font-weight-bold text-primary">Consent Form Data</h6>
                        </div>
                        <div className="card-body text-bg-success p-3">
                           
                            <div className="custom-table">
    
                            <div className="p-d-flex p-ai-center p-mb-4">
                 
                <InputText
                    value={filters.global.value} placeholder='Search'
                    onChange={(e) => setFilters({ ...filters, global: { value: e.target.value, matchMode: 'contains' } })}
                />
            </div>
      <DataTable value={list}  size="small"  globalFilterFields={['partner', 'agent_name', 'vehicle','mobile','pancard']} filters={filters}
        showGridlines
        stripedRows  paginator
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks
        NextPageLink LastPageLink"
        rows={5} header={header}  ref={dt} removableSort >
      <Column header="SR NO" body={(rowData, { rowIndex }) => rowIndex + 1} style={{ width: '80px'}}></Column>
          <Column field="partner" header="Db Code"></Column>
          <Column field="agent_name" header="Agent Name" sortable style={{ width: '200px' }}></Column>
          <Column field="mobile" header="Mobile" sortable></Column>
          <Column field="vehicle" header="Vehicle" sortable></Column>
          <Column field="address" header="Address"></Column>
          <Column field="city1" header="City"></Column>
          <Column field="state_name" header="State"></Column>
          <Column field="uid" header="PIN"></Column>
          <Column field="pancard" header="Pancard"></Column>
          <Column field="bankname" header="Bank Name"></Column>
          <Column field="bankac" header="Bank Account"></Column>
          <Column field="ifsc" header="IFSC"></Column>
          <Column field="name1" header="Agency/Depo" sortable></Column>         
          <Column field="edition" header="Edition"></Column>                
          <Column field="create_date" header="Create Date" body={(rowData) => new Date(rowData.create_date).toDateString()}></Column>
          <Column field="user_type" header="User Type" body={(rowData) => rowData.user_type === 1 ? "ASD" : "HW"}></Column>
      </DataTable>
    </div>
                        </div>
                    </div>
            

            
         

        </div>
      

    </div> 
 
    <Footeradmin/>

</div> 
</div>
    </>
  )
}

export default Listconsentform